<template>
  <div class="d-flex flex-column overflow-auto vh-100">

    <edit-property @hidden="selectedProperty = undefined" id="edit-property" :property="selectedProperty" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-checkbox class="align-self-center mr-2" v-model="showDeleted">Show Archived Properties</b-checkbox>
        <b-button class="ml-2" variant="primary" :to="{name: 'properties.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-3">
        <b-col cols="12">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query" />
        </b-col>
      </b-row>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-row>
        <b-col cols="12">
          <b-list-group class="mb-4" v-if="!loading">
            <b-table sortable responsive outlined :items="formattedData" :fields="[{key: 'name', sortable: true}, 'website_id', {key: 'max_guests', sortable: true}, 'key', 'postcode', 'details', 'actions']">
              <template #cell(actions)="row">
                <div class="align-self-center d-flex flex-md-row flex-column">
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(row.item.id)" variant="primary" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="trash(row.item.id)" variant="danger" v-b-popover.top.hover="`Archive Property`" v-if="!row.item.deleted_at"><fa-icon icon="trash" /></b-button>
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="restore(row.item.id)" variant="success" v-b-popover.top.hover="`Restore Property`" v-if="row.item.deleted_at"><fa-icon icon="trash-restore" /></b-button>
                </div>
              </template>
            </b-table>
          </b-list-group>
          <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditProperty from './Modals/EditProperty.vue'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { EditProperty, StickyHeader },
  data() {
    return {
      query: '',
      selectedProperty: {},
      formattedData: [],
      showDeleted: false
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.fetch();
  },
  computed: {
    ...mapState('properties', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('properties', ['fetch', 'delete']),
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'properties', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'properties', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedProperty = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'properties.update' && this.$route.params.propertyId === id)) {
        this.$router.push({ name: 'properties.update', params: { propertyId: id } });
      }
    },
    trash(id) {
      const property = this.data.find(e => e.id === parseInt(id))
      let message = "Are you sure you want to archive this property? You can later restore a property, at any time.";

      if (property.futureBookingCount > 0) {
        message += ' There are currently ' + property.futureBookingCount + ' future bookings for this property.';
      }
      if (property.unapprovedStatementCount > 0) {
        message += ' There are currently ' + property.unapprovedStatementCount + ' unapproved statements for this property.';
      }

      this.$bvModal.msgBoxConfirm(message, {
        title: 'Archive Property',
        okText: 'Yes, Archive',
        okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete(id).then(() => this.load());
      });
    },
    restore(id) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to restore this property?", {
        title: 'Restore Property',
        okText: 'Yes, Restore',
        okVariant: 'success',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete({ id, restore: true }).then(() => this.load());
      });
    },
    async load() {
      return await this.fetch({
        query: this.query,
        page: this.$route.query.page || 1,
        showDeleted: this.showDeleted
      });
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      let self = this;
      this.formattedData = map(data, function(property){
        let keyMsg = 'Not Set';

        if (property.key) {
          if (property.key.key_hook !== 'N/A' && property.key.key_hook !== '') {
            if (property.key.keybox_code) {
              keyMsg = property.key.key_hook + ':' + property.key.keybox_code;
            } else {
              keyMsg = property.key.key_hook;
            }
          } else if (property.key.keybox_code) {
            keyMsg = property.key.keybox_code;
          }
        }
        return {
          id: property.id,
          name: property.name,
          website_id: property.website_property_id,
          max_guests: property.max_guests,
          key: keyMsg,
          postcode: property.address.postcode,
          details: property.bedrooms + " " + self.pluralise(property.bedrooms, 'bedroom') + " and " + property.bathrooms + " " + self.pluralise(property.bathrooms, 'bathroom'),
          deleted_at: property.deleted_at
        }
      })
    },
    showDeleted(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$route.query.page = 1;
      this.load();
    }
  }
}
</script>

<style>

</style>
